<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="d-flex flex-row-fluid flex-column bgi-size-cover bgi-position-center bgi-no-repeat p-10 p-sm-30"
      :style="{
        backgroundImage: `url(${backgroundImage})`
      }"
    >
      <!-- begin:: Content -->
      <h1
        class="font-size-sm-100 font-weight-boldest text-dark-75 mt-15"
        style="font-size: 150px;"
      >
        Oops!
      </h1>
      <p class="display-4 font-weight-light w-30 py-6">
        Her yere baktık fakat aradığınız sayfayı bulamadık :(
      </p>

      <p class="text-dark-50 font-weight-bolder font-size-h6">Hata kodu: 404</p>

      <p class="text-dark-50 font-weight-normal font-size-h6 pt-2 mb-1">İşte bazı yararlı bağlantılar:</p>
      
      <router-link :to="{ name: 'dashboard'}" v-slot="{ href }">
        <a :href="href" class="font-weight-normal text-primary font-size-h6 pb-1">Giriş Sayfası</a>
      </router-link>
      <router-link :to="{ name: 'experiences'}" v-slot="{ href }">
        <a :href="href" class="font-weight-normal text-primary font-size-h6 pb-1">Deneyimler</a>
      </router-link>
      <router-link :to="{ name: 'reservations'}" v-slot="{ href }">
        <a :href="href" class="font-weight-normal text-primary font-size-h6 pb-1">Rezervasyonlar</a>
      </router-link>
      <router-link :to="{ name: 'destinations'}" v-slot="{ href }">
        <a :href="href" class="font-weight-normal text-primary font-size-h6 pb-1">Destinasyonlar</a>
      </router-link>
      <!-- end:: Content -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Error-1",
  mounted() {},
  computed: {
    backgroundImage() {
      return process.env.BASE_URL + "media/error/bg5.jpg";
    }
  }
};
</script>
